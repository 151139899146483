@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
    *, *:after, *:before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    }
    body {
    padding:  40px;
    max-width: 460px;
    margin:  auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(236,236,236);
    background-color: #999999;
    }
    .card {
    border-radius: 0.75rem;
    background-color: #fff;
    overflow: hidden;
    }
    .info__padding {
    padding:  40px 40px 30px 40px;
    }
    h3 {
    font-size: 19px;
    margin-bottom: 12px;
    font-weight: 600;
    }
    p {
    font-size: 16px;
    font-weight: 400;
    }
    h3, p {
    color: #0B1A28;
    text-align: center;
    }
    .button__group button {
    color: #007AFF;
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border:  none;
    border-top:  1px solid rgb(220,224,226);
    border-left: 1px solid rgb(220,224,226);
    padding: 18px 10px;
    cursor: pointer;
    float: left;
    width: 50%;
    }
    .button__group:after {
    content: "";
    clear: both;
    display: table;
    }
    .button__group button:not(:last-child) {
    border-left: none;
    }
    .button__group button:hover {
    background-color: rgb(250,250,250);
    }
    .button__group button:active {
    background-color: rgb(245,245,245);
    }
    @media only screen and (max-width: 600px) {
    body {
    padding:  30px;
    }
    }